<template>
  <div class="goodsAttention">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="moreOnLoad"
    >
      <ul class="list">
        <li
          class="item"
          v-for="item in favoriteProductData"
          :key="item.ProductNumber"
          @click="getProductDetails(item.ProductNumber, item.ShopId)"
        >
          <figure class="productLogo">
            <van-image
              class="productLogoIcon"
              :src="item.ProductPhoto && imgUrlFilter(item.ProductPhoto)"
            >
              <template v-slot:error>
                <img class="ImgIcon" src="~assets/img/other/empty.gif" />
              </template>
            </van-image>
          </figure>
          <section class="productInfo">
            <div class="productTitle">
              <div class="discount" v-if="item.IsCouponProduct">
                {{ $t.discount }}
              </div>
              <div class="brand" v-if="item.IsBrandProduct">
                {{ $t.brand }}
              </div>
              <div class="attestation" v-if="item.IsAttestationProduct">
                {{ $t.attestation }}
              </div>
              <span class="name">{{ item.ProductName }}</span>
            </div>
            <div class="productNotice" v-if="item.Status == 0">
              ({{ $t.cart.productOff }})
            </div>
            <div class="productPrice">
              <span class="productPriceTitle"
                >{{ $t.goodsAttention.sellingPrice }}：</span
              >
              <span class="productPriceNum"
                >${{ Math.round(item.MinPrice * 100) / 100 }}</span
              >
            </div>
          </section>
          <figure class="delLogo" @click.stop="del(item)">
            <img
              class="delLogoIcon"
              src="~assets/img/footprint/icon_list_delete@2x.png"
            />
          </figure>
        </li>
      </ul>
      <placeholder v-if="isPlaceholder"></placeholder>
    </van-list>
  </div>
</template>
<script>
import Vue from "vue";
import { List } from "vant";
import Placeholder from "@/components/common/Placeholder";
Vue.use(List);
export default {
  name: "GoodsAttention",
  components: {
    Placeholder,
  },
  data() {
    return {
      pageIndex: 1,
      favoriteProductData: [],
      finished: false,
      loading: true,
      isfirst: true,
      isPlaceholder: false,
    };
  },
  computed: {
    finishedText() {
      if (this.isPlaceholder) {
        return "";
      } else {
        return this.$t.noMoreData;
      }
    },
  },
  mounted() {
    this.favoriteProductByPage();
  },
  methods: {
    // 跳转详情页
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    // 获取关注商品数
    favoriteProductByPage() {
      this.$commonMethod.showLoading();
      let param = {
        PageIndex: this.pageIndex,
      };
      this.$api.follow
        .favoriteProductByPage(param)
        .then((res) => {
          // this.isfirst = false
          this.loading = false;
          console.log(res);
          if (res.data == null || res.data === "" || res.data.length === 0) {
            this.isPlaceholder = true;
          } else {
            this.favoriteProductData = res.data;
            this.isPlaceholder = false;
          }
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          // this.isfirst = false
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
      // console.log("这是isfirst" + this.isfirst);
    },
    moreOnLoad() {
      // console.log('加载更多')
      // this.loading = false
      // this.finished = false
      // if (this.isfirst) return
      console.log("执行加载更多");
      let param = {
        PageIndex: this.pageIndex + 1,
      };
      this.loading = true;
      this.$api.follow
        .favoriteProductByPage(param)
        .then((res) => {
          console.log(res);
          const data = res.data;
          if (data == null || data === "" || data.length === 0) {
            this.loading = false;
            this.finished = true;
            // console.log('无数据')
          } else {
            this.favoriteProductData = this.favoriteProductData.concat(data);
            this.pageIndex = this.pageIndex + 1;
            this.loading = false;
            this.finished = false;
            // console.log('有数据')
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 删除收藏
    del(item) {
      this.$commonMethod.dialogConfirm(
        this.$t.cancelCollection,
        (_) => {
          this.addFavoriteProduct(item);
        },
        (_) => {}
      );
    },
    // 取消获取喜爱
    addFavoriteProduct(item) {
      this.$commonMethod.showLoading();
      let param = {
        ShopId: item.ShopId,
        ProductNumber: item.ProductNumber,
        IsFavorite: 0,
      };
      this.$api.follow
        .addFavoriteProduct(param)
        .then((res) => {
          this.$commonMethod.hideLoading();
          this.$commonMethod.showToast({
            type: "success",
            titleMsg: res.message,
          });
          this.favoriteProductByPage();
        })
        .catch((error) => {
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
      // console.log("这是isfirst" + this.isfirst);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin liItem {
  align-items: center;
  -webkit-align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 17px 15px;
  margin-top: 15px;
  border-radius: 8px;
  @include publicFlex;
}
.goodsAttention {
  width: 100%;
  ::v-deep .van-list__finished-text {
    font-size: 14px;
    padding: 14px 0;
    //display: block;
  }
  .list {
    margin: 0 auto;
    list-style: none;
    width: 92%;
    .item {
      @include liItem;
      .productLogo {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-right: 15px;
        margin-left: 0px;
        @include publicFlex;
        .productLogoIcon {
          width: 75px;
          height: 75px;
          object-fit: cover;
          -webkit-object-fit: cover;
          .ImgIcon {
            width: 75px;
            height: 75px;
            object-fit: cover;
          }
          ::v-deep .van-image__img {
            width: 75px;
            height: 75px;
            position: relative;
            object-fit: cover;
          }
          ::v-deep .van-image__error {
            width: 75px;
            height: 75px;
            position: relative;
            object-fit: cover;
          }
        }
      }
      .productInfo {
        flex: 1;
        -webkit-flex: 1;
        min-height: 75px;
        display: flex;
        -webkit-display: flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        .productTitle {
          color: #000000;
          font-size: 12px;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
          white-space: normal;
          .discount {
            vertical-align: middle;
            display: inline-block;
            background: red;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 5px;
          }
          .brand {
            vertical-align: middle;
            display: inline-block;
            background: #e6a23c;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 5px;
          }
          .attestation {
            vertical-align: middle;
            display: inline-block;
            background: #22b196;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 5px;
          }
          .name {
            vertical-align: middle;
            display: inline-block;
          }
        }
        .productNotice {
          color: #ff625c;
          font-size: 12px;
        }
        .productPrice {
          .productPriceTitle {
            color: #000000;
            font-size: 12px;
          }
          .productPriceNum {
            color: #ff625c;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
      .delLogo {
        justify-content: space-between;
        -webkit-justify-content: space-between;
        width: 55px;
        margin: 0;
        @include publicFlex;
        .delLogoIcon {
          width: 25px;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
